// CSS
import '../sass/guest.scss';

// Vue JS
import { createApp } from 'vue';
import { createRouter, createWebHistory} from 'vue-router';
import { createStore } from 'vuex';
import { createI18n } from 'vue-i18n';

// Vue Init -----------------------------------
import root from './participant.vue';
const app = createApp(root);

// Vuetify
// import 'vuetify/styles'
import '../sass/guest.scss'
import '@mdi/font/css/materialdesignicons.css'
import { createVuetify } from 'vuetify'
import colors from 'vuetify/lib/util/colors'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
    components, directives,
    theme: {
        themes: {
          light: {
            dark: false,
            colors: {
              primary: colors.lightGreen.darken1, // #E53935
              secondary: colors.grey.darken4, // #FFCDD2     
              background: colors.lightGreen.lighten4,
            }
          },
        },
    },
})
app.use(vuetify);

// Vue Router ---------------------------------
import routes from './participant_routes.js'
const router = createRouter({routes, history: createWebHistory()});
app.use(router);

// Vuex Store ---------------------------------
const getDefaultState = () => {
    return {
        user: {
            id: null,
            survey_id: null,
            ou_id: null,
            started_at: null,
            finished_at: null
        },
        survey: {
            name: '',
            text_start: '',
            text_end: '',
            text_help: '',
            scopes: [],
            default_slider: null,
            default_scale: null
        },
        connection: {
            connected: true,
            open_requests: [],
            timeout: 3000
        },
        modal: {
            title: '',
            message: '',
            render: false
        },
        languages: [
            {name: 'deutsch', language_code: 'de'},
            {name: 'englisch', language_code: 'en'},
        ],
        language: {name: 'deutsch', language_code: 'de'},
    }
}
const store = createStore({
    state: getDefaultState(),
    getters: {
        /**
         * Returns the percentage of the unsanswered to existing questions ratio
         * @param state 
         * @returns percentage of survey complentness
         */
        survey_progress: state => {
            var items_count = 0;
            var items_finished_count = 0;
            // Iteralte through scopes
            state.survey.scopes.forEach(scope => {
                
                // Iterate through items
                scope.items.forEach(item => {

                    items_count++;
                    if (item.answer != null 
                        && item.answer.answer != null 
                        && item.answer.answer != ''){
                        items_finished_count++;
                    }

                });                

            });      
            return parseInt((items_finished_count / items_count) * 100);
        },
        /**
         * Returns the next scope in order (of the passed one)
         * @param scope_id
         * @returns scope
         */
        scope_after: (state) => (scope_id) => {
            // Iterate through scopes
            var trigger = false;

            for (let index = 0; index < state.survey.scopes.length; index++) {
                const scope = state.survey.scopes[index];
                if (scope.id === scope_id) {
                    trigger = true;                                       
                }
                else if (trigger){                    
                    return scope;
                }
            }

            if (state.survey.scopes[0] != null) {                
                return state.survey.scopes[0];
            }
            else{
                return null;
            }
        },
        /**
         * Returns the previous scope in order (of the passed one)
         * @param scope_id
         * @returns scope 
         */
        scope_before: (state) => (scope_id) => {
            // Iterate through scopes
            var trigger = false;

            for (let index = state.survey.scopes.length-1; index > -1; index--) {
                const scope = state.survey.scopes[index];
                if (scope.id === scope_id) {
                    trigger = true;                                       
                }
                else if (trigger){                    
                    return scope;
                }
            }

            if (state.survey.scopes[0] != null) {                
                return state.survey.scopes[0];
            }
            else{
                return null;
            }
        },
        /**
         * Checks if scope has required unsanswered questions
         * @param state 
         * @returns 
         */
        scope_is_incomplete: (state) => (scope_id) => {
            var items = [];            
            // Iteralte through scopes
            state.survey.scopes.forEach(scope => {
                
                if (scope.id === scope_id) {
                    // Iterate through items
                    scope.items.forEach(item => {                   
                        if ((item.answer == null 
                            || item.answer.answer == null 
                            || item.answer.answer == '')
                            && item.required){                          
                            var pItem = item;
                            pItem['scope_id'] = scope.scope_id;   
                            pItem['unique_scope_id'] = scope.id;                        
                            items.push(item);
                        }
                    });                
                }

            });      
            return items.length > 0;
        },
        /**
         * Returns if the passed scope_id equals the id of the last scope in order
         * @param scope_id 
         * @returns boolean
         */
        scope_is_last: (state) => (scope_id) => {            
            return state.survey.scopes[state.survey.scopes.length-1].id == scope_id;
        },
        /**
         * Returns the first scope in order
         * @param state 
         * @returns scope
         */
        first_scope: (state) => {
            if (state.survey.scopes[0] != null) {                
                return state.survey.scopes[0];
            }
            else{
                return {id: 0};
            }
        },
        /**
         * Returns the last of all scopes in order
         * @param state 
         * @returns 
         */
        last_scope: (state) => {
            return state.survey.scopes[state.survey.scopes.length-1];
        },
        /**
         * Returns all required and unsanswered questions as an array
         * @param state 
         * @returns 
         */
        required_unanswered_items: state => {
            var items = [];            
            // Iteralte through scopes
            state.survey.scopes.forEach(scope => {
                
                // Iterate through items
                scope.items.forEach(item => {                   
                    if ((item.answer.answer == null || item.answer.answer === '')
                        && item.required){
                        var pItem = item;
                        pItem['scope_id'] = scope.scope_id;   
                        pItem['scope_survey_id'] = scope.id;                        
                        items.push(item);
                    }
                });                

            });      
            return items;
        }
    },
    mutations: {
        /**
         * Resets the Store to default State
         * @param state 
         */
        resetState (state) {
            Object.assign(state, getDefaultState())
        },
        /**
         * Sets Modal Data and shows modal
         * @param state 
         * @param modalData 
         */
        showModal(state, data){
            state.modal.title = data.title;
            state.modal.message = data.message;
            state.modal.render = true;
        },
        /**
         * Hides the Modal
         * @param state 
         */
        hideModal(state){
            state.modal.render = false;
            state.modal.title = '';
            state.modal.message = '';
        },
        /**
         * Sets the user state
         * @param state 
         * @param user 
         */
        setUser(state, user){
            state.user = user
        },
        /**
         * Sets the survey state
         * @param state 
         * @param survey 
         */
        setSurvey(state, survey){
            state.survey = survey
        },
        /**
         * Sets the connected state to true
         * @param  state 
         */
        connected(state){
            state.connection.connected = true
        },
        /**
         * Sets the connected state to false
         * @param state 
         */
        disconnected(state){
            state.connection.connected = false
        },
        /**
         * Currently not used. Adds a new request object to the open_requests array
         * @param state 
         * @param request 
         */
        pushRequest(state, request){
            state.connection.open_requests.push(request)
        },
        /**
         * Currentyl not used. Removes a request from the open_requests array
         * @param {*} state 
         * @param {*} index 
         */
        removeRequest(state, index){
            state.connection.open_requests.splice(index, 1)
        },
        updateItemAnswer(state, data){
            
            state.survey.scopes.forEach(scope => {

                scope.items.forEach(item => {
                    
                    if(item.id === data.item_id){
                        item.answer = {answer: data.participant_answer};
                    }

                });

            });

        },
        setLanguages(state, data){
            state.languages = data;
        },
        setLanguage(state, language){
            state.language = language;
        }
    }
});
app.use(store);

// Vue I18n
import de from './localization/guest/de.json'
import en from './localization/guest/en.json'

const languages = {
    en: en,
    de: de,
  }
const messages = Object.assign(languages)

const i18n = createI18n({
    locale: 'de', // set locale
    messages, // set locale messages
    legacy: false,
    fallbackLocale: 'de'
})
app.use(i18n);

app.mount('#app');