<template>
    <v-card class="pa-4" style="max-width: 800px; width: 800px; margin-bottom: 128px;">
        <div class="d-flex flex-column">
            <h1 class="text-h5 text-md-h4 mb-2 mb-sm-4">{{ t('language') }}</h1>
            <p class="line-height-2 line-height-sm-3 text-body-1 text-md-h6 font-weight-regular mb-4">{{ t('selectLanguage') }}</p>
            <v-select variant="outlined" v-model="selectedLanguage" :items="languages" item-title="name" item-value="id" :label="t('language')" @update:model-value="handleUpdateModelValue"></v-select>
            <v-btn color="primary" @click="handleSelect" :disabled="selectedLanguage === null" class="align-self-end">{{ t('select') }}</v-btn>
        </div>
    </v-card>
</template>
<script setup>
import { computed } from 'vue';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex';

const {t} = useI18n();

const store = useStore();
const vxSetLanguage = language => store.commit('setLanguage', language);
const vxLanguages = computed(() => {return store.state.languages});

const props = defineProps({
    languages: {
        type: Array,
        default: []
    }
});

const selectedLanguage = ref(null);
const emit = defineEmits(['select'])

const handleUpdateModelValue = (value) => {
    const lang = props.languages.find(language => language.id === value);
    
    if (lang === undefined) {
        vxSetLanguage(vxLanguages.value[0]);
        return;
    }
    else{
        vxSetLanguage(lang);
    }
}

const handleSelect = () => {
    emit('select', props.languages.find(language => language.id === selectedLanguage.value));
}

</script>
<i18n>
    {
        "de": {
            "select": "Auswählen",
            "selectLanguage": "Bitte wähle deine Sprache aus",
            "language": "Sprache",
            "end": "Ende",
            "start": "Zum Anfang",
            "connection-lost": {
                "title": "Verbindung zum Server verloren",
                "message": "Wir versuchen gerade, die Verbindung wieder herzustellen..."
            }
        },
        "en": {
            "select": "Select",
            "selectLanguage": "Please select your language",
            "language": "Language",
            "end": "End",
            "start": "Intro",
            "connection-lost": {
                "title": "Connection lost",
                "message": "We are trying to reconnect you..."
            }
        }
    }
</i18n>