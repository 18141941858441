<template>
    <div class="d-flex h-100 w-100 justify-center py-4 py-sm-8 px-2 px-sm-4 px-md-8 align-center">
        <template v-if="survey != null">
            <!-- <div class="d-flex flex-column gap-4 pa-4" style="position: absolute; right: 0px; bottom: 0px;">
                    <v-btn v-for="lang in survey.languages" class="pa-2" color="primary" @click="updateSurveyLanguage(lang)">{{lang.name}}</v-btn>
                </div> -->
            <v-slide-x-transition hide-on-leave>
                <language-select-card v-if="localRoute == 'language'" :languages="survey.languages" @select="handleLanguageSelect"></language-select-card>
                <intro-card v-else-if="localRoute == 'intro'" @start="handleSurveyStart" :survey-title="survey.name" :intro-text="survey.text_start"></intro-card>
                <div v-else-if="localRoute == 'survey'" class="overflow-auto">
                    <div class="pb-12 d-flex flex-column overflow-auto gap-3 gap-sm-4 survey-wrapper">
                        <div class="px-1">
                            <v-card class="pa-4 border-l-3 border-l-sm-4 border-primary">
                                <h1 class="text-h5 text-sm-h4 font-weight-thick">{{survey.name}}</h1>
                                <div class="text-subtitle-1">{{ t('section') }}</div>
                                <v-tabs v-model="currentScopeTab" show-arrows center-active slider-color="primary" class="survey-scope-tabs">
                                    <v-tab v-for="scope in survey.scopes" :value="scope.id" class="px-2 font-weight-thick text-subtitle-2 text-sm-subtitle-1">{{ scope.name }}</v-tab>
                                </v-tabs>
                            </v-card>
                        </div>
                        <v-window v-model="currentScopeTab">
                            <v-window-item v-for="scope in survey.scopes" :value="scope.id">
                                <survey-scope 
                                    v-model:completed="scope.completed"
                                    :showErrorMessages="scope.showErrorMessages"
                                    :name="scope.name" 
                                    :description="scope.text_info" 
                                    v-model:items="scope.items"
                                    @update:item="handleItemUpdate"
                                    @lost:connection="handleLostConnection"
                                ></survey-scope>
                            </v-window-item>
                        </v-window>
                        <div @click="handleDebug" class="d-flex justify-end w-100 gap-3 gap-md-4 px-1 scope-navigation-buttons">
                            <v-btn size="large" color="primary" @click="handlePreviousScopeButton">
                            <v-icon>mdi-chevron-left</v-icon></v-btn>
                            <v-btn size="large" color="primary" @click="handleNextScopeButton"><v-icon>mdi-chevron-right</v-icon></v-btn>
                        </div>
                    </div>
                </div>
                <outro-card v-else-if="localRoute == 'outro'" :survey-title="survey.name" :outro-text="survey.text_end" @back="localRoute = 'survey'" 
                @finish="handleSurveyFinish"
                @validation-error="handleValidationError"></outro-card>
                <after-submit v-else-if="localRoute == 'finish'" @timeout="handleFinishTimeout"></after-submit>
            </v-slide-x-transition>
        </template>
        <v-dialog v-model="connectionLostDialog" persistent content-class="d-flex justify-center align-center">
            <v-card class="pa-4" width="fit-content">
                <div class="d-flex flex-sm-row flex-column align-center" style="gap: 12px">
                    <div class="d-flex flex-column align-center align-sm-start">
                        <div class="text-subtitle-1 text-sm-h6 text-center text-sm-left" :class="{'font-weight-bold': xs}">{{ t('connection-lost.title') }}</div>
                        <div class="text-sm-subtitle-2 text-body-2 text-center text-sm-left">{{ t('connection-lost.message') }}</div>
                    </div>
                    <v-progress-circular color="primary" indeterminate></v-progress-circular>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script setup>
import axios from 'axios';
import {useRouter, useRoute} from 'vue-router';
import {ref, onMounted, onUnmounted, watch, toRef, nextTick, computed} from 'vue';
import { useI18n } from 'vue-i18n'
import { useDisplay } from 'vuetify';
import { useStore } from 'vuex';
// Components
import introCard from './components/introCard.vue';
import surveyScope from './components/surveyScope.vue';
import outroCard from './components/outroCard.vue';
import afterSubmit from './components/afterSubmit.vue';
import languageSelectCard from './components/languageSelectCard.vue';

const {t} = useI18n();
const {mdAndUp, xs} = useDisplay();
const router = useRouter();
const emit = defineEmits(['finish']);
const store = useStore();

// Properties ------------------------------------------------------------
const props = defineProps({
    authenticated: {type: Boolean, default: false}
});

// Data ------------------------------------------------------------------
const survey = ref(null);
const localRoute = ref('intro');
const surveyLanguage = ref(null);
const currentScopeTab = ref(null);
const connectionLostDialog = ref(false);
const vxSetLanguages = data => store.commit('setLanguages', data);
const vxLanguage = computed(()=>{return store.state.language});
var connectionCheckTimeout = null;

// Computed --------------------------------------------------------------
const currentScopeIsLast = computed(function(){
    if (!!survey.value.scopes) {
        return currentScopeTab.value == survey.value.scopes[survey.value.scopes.length-1].id;
    }
})

const currentScopeIsFirst = computed(function(){
    if (!!survey.value.scopes && survey.value.scopes.length > 0) {
        return currentScopeTab.value == survey.value.scopes[0].id;
    }
})

// Functions -------------------------------------------------------------
const assignItemScale = function(item, defaultScale, defaultSlider){
    if (item.scale != null) return;
    switch(item.type_id){
        case 1:
        case 6:
            break;
        case 2:
        case 3:
        case 5:
            item.scale = defaultScale;
            break;
        case 4:
            item.scale = defaultSlider;
            break;
    }
}

const isItemValidated = function(item){
    return Boolean(!item.required || (item.required && answerGiven(item.answer.answer)));
}

const prepareSurveyData = function(survey){
    survey.scopes.forEach(scope => {
        scope.items.forEach(item => {
            assignItemScale(item, survey.default_scale, survey.default_slider);
            item.validated = isItemValidated(item);
        })
    })

    if(survey.languages != null && survey.languages.length > 0){
        if (survey.languages.length > 0) {
            surveyLanguage.value = survey.languages[0];
            vxSetLanguages(survey.languages);
        }
        // console.log('survey brings langs', survey.languages)
    }

    if (survey.language_select_before === true) {
        localRoute.value = 'language';
    }
    // console.log('localroute', localRoute.value)
    return survey;
}

const getScope = function(scopeId, scopes = survey.value.scopes){
    const filteredScopes = scopes.filter(scope => scope.id == scopeId);
    if (filteredScopes.length != 1) {
        return null;
    }
    return filteredScopes[0];
}

const getScopeAfter = function(scopeId, scopes = survey.value.scopes){
    if (!!!scopes || !Array.isArray(scopes)) {
        return null;
    }
    var nextScope = null;
    scopes.every((scope, index) => {
        if (scope.id != scopeId) {
            return true;
        }
        if (index < scopes.length-1) {
            nextScope = scopes[index+1]; 
            return false;                   
        }
        nextScope = scopes[0];
        return false;
    })
    return nextScope;
}

const getScopeBefore = function(scopeId, scopes = survey.value.scopes){
    if(!!!scopes || !Array.isArray(scopes)){
        return null;
    }
    var previousScope = null;
    scopes.every((scope, index) => {
        if (scope.id != scopeId) {
            return true;
        }
        if (index > 0) {
            previousScope = scopes[index-1];
            return false;
        }
        previousScope = scopes[scopes.length-1];
        return false;
    })
    return previousScope;
}

const getScopesExcept = function(exceptId, scopes){
    return scopes.filter(scope => scope.id != exceptId);
}

const answerGiven = function(answer){
    if (!Boolean(answer)) {
        return false;
    }

    if (Array.isArray(answer) && answer.length == 0) {
        return false;
    }

    if (typeof answer === 'object' && Object.keys(answer).length == 0) {
        return false;
    }

    return true;
}

const validateScope = function(scope){
    const failedItems = [];
    if (!!!scope.items) {
        scope.validated = true;
        return true;
    }
    scope.items.forEach(item => {
        // console.log('item',item);
        if (!answerGiven(item.answer.answer) && item.required == true) {
            failedItems.push(item); 
            item.validated = false;  
        }
        else{
            item.validated = true;
        }
    })
    scope.validated = Boolean(failedItems.length == 0);
    return !!scope.validated;
}

const validateScopes = function(scopes){
    var valid = true;
    scopes.forEach(scope => {
        if(!validateScope(scope)){
            valid = false;
        }
    })
    return valid;
}

const validateSurvey = function(survey){
    if (!!!survey.scopes) {
        survey.validated = true;
        return true;
    }
    survey.validated = validateScopes(survey.scopes);
    return survey.validated;
}

const scopesShowErrorMessages = function(scopes, show = true){
    scopes.forEach(scope => {
        scope.showErrorMessages = show;
    })
}

const getFirstInvalidScope = function(scopes){
    return scopes.find(scope => !!scope.validated == false);
}

const getFirstInvalidItem = function(items){
    return items.find(item => item.validated == false);
}

const getItem = function(itemId, survey){
    var found = undefined;
    survey.scopes.every(scope => {
        if (found !== undefined) {
            return false;    
        }
        scope.items.every(item => {
            if (itemId == item.id) {
                found = item;
                return false;
            }
            return true;
        })
        return true;
    })
    return found;
}

const scrollToElement = function(selector){
    document.querySelector(selector).scrollIntoView({behavior: 'smooth', block: 'center'});
}

const scrollToFirstInvalidItem = function(items){
    const firstInvalidItem = getFirstInvalidItem(items);
    if (firstInvalidItem !== undefined) {        
        nextTick(()=>{
            // scrollToElement('#survey-item-'+firstInvalidItem.id);
            const position = document.querySelector('#survey-item-'+firstInvalidItem.id).offsetTop;
            scrollSmoothlytoPosition(position);
        })
    }
}

const updateItemLanguage = (item, language) => {
    // console.log('item', item);
    // console.log('language', language);
    if (item.question_translations.length == 0) {
        return;
    }

    const searchResult = item.question_translations.find(translation => translation.language.id == language.id);
    if (searchResult === undefined) {
        item.text_question = item.question_translations[0].content;
    }
    else{
        item.text_question = searchResult.content;
    }
}

const updateScopeLanguage = (scope, language) => {
    scope.items.forEach(item => {
        updateItemLanguage(item, language);
    })
}

const updateSurveyTextLanguage = (language) => {
    if (survey.value.start_text_translations.length > 0) {
        const searchResult = survey.value.start_text_translations.find(translation => translation.language_id == language.id);
        if (searchResult === undefined) {
            survey.value.text_start = survey.value.start_text_translations[0].content;
        }
        else{
            survey.value.text_start = searchResult.content;
        }
    }

    if (survey.value.end_text_translations.length > 0) {
        const searchResult = survey.value.end_text_translations.find(translation => translation.language_id == language.id);
        if (searchResult === undefined) {
            survey.value.text_end = survey.value.end_text_translations[0].content;
        }
        else{
            survey.value.text_end = searchResult.content;
        }
    }

}

const updateSurveyLanguage = (language) => {
    updateSurveyTextLanguage(language);
    survey.value.scopes.forEach(scope => {
        updateScopeLanguage(scope, language);
    })
}

function scrollSmoothlytoPosition(position = 0, time) {
    var pos = position;
    var currentPos = window.scrollY;
    var start = null;
    if(time == null) time = 200;
    pos = +pos, time = +time;
    window.requestAnimationFrame(function step(currentTime) {
        start = !start ? currentTime : start;
        var progress = currentTime - start;
        if (currentPos < pos) {
            window.scrollTo(0, ((pos - currentPos) * progress / time) + currentPos);
        } else {
            window.scrollTo(0, currentPos - ((currentPos - pos) * progress / time));
        }
        if (progress < time) {
            window.requestAnimationFrame(step);
        } else {
            window.scrollTo(0, pos);
        }
    });
}

// Handlers ---------------------------------------------------------------
const handleSurveyStart = function(){
    localRoute.value = 'survey';
}

const handleSurveyFinish = function(){
    localRoute.value = 'finish';
    emit('finish');
}

const handleValidationError = function(){
    localRoute.value = 'survey';
}

const handlePreviousScopeButton = function(){
    if (currentScopeIsFirst.value) {
        localRoute.value = 'intro';
    }
    else{
        currentScopeTab.value = getScopeBefore(currentScopeTab.value).id;
    }
}

const handleNextScopeButton = function(){
    if (currentScopeIsLast.value) {
        handleLastScopeNextButton();
        return;
    }

    if(validateScope(getScope(currentScopeTab.value))){
        currentScopeTab.value = getScopeAfter(currentScopeTab.value).id;
        scrollSmoothlytoPosition(0, 300);
        return;
    }
    
    const currentScope = getScope(currentScopeTab.value, survey.value.scopes);
    currentScope.showErrorMessages = true;
    scrollToFirstInvalidItem(currentScope.items);
}

const handleLastScopeNextButton = function(){
    if (validateSurvey(survey.value)) {
        localRoute.value = 'outro';
        return;
    }        
    // console.log('survey', survey.value)

    scopesShowErrorMessages(survey.value.scopes);
    const lastScope = survey.value.scopes[survey.value.scopes.length-1];
    if (!lastScope.validated) {
        scrollToFirstInvalidItem(lastScope.items);
        return;
    }

    const firstInvalid = getFirstInvalidScope(survey.value.scopes);
    if (!!firstInvalid) {
        currentScopeTab.value = firstInvalid.id;
        scrollToFirstInvalidItem(firstInvalid.items);
        return;
    }
    // console.log('an unexpected error occured');     
}

const handleItemUpdate = function(itemCopy){
    var item = getItem(itemCopy.id, survey.value);
    item.validated = answerGiven(item.answer.answer);    
}

const handleFinishTimeout = function(){
    router.push('/home');
}

const handleLostConnection = function(){
    connectionLostDialog.value = true;
    clearTimeout(connectionCheckTimeout);
    checkConnection()
    .then(response => {
        connectionLostDialog.value = false;
    })
    .catch(error => {
        connectionCheckTimeout = setTimeout(()=>{
            handleLostConnection();
        }, 2500)
    })
}

const handleLanguageSelect = language => {    
    localRoute.value = 'intro';
    surveyLanguage.value = language;
}

// API Calls --------------------------------------------------------------
const fetchSurvey = function(){
    axios.get('/participant/survey')
    .then(rsp => {        
        survey.value = prepareSurveyData(rsp.data.data.survey);
        // console.log('survey:',survey.value);
    });
}

const checkConnection = async function(){
    return await axios.post('/connection/check');
}

// Watchers ---------------------------------------------------------------
watch(toRef(props, 'authenticated'), function(newValue){
    // console.log('props auth',props.authenticated)
    if (newValue) {
        fetchSurvey();     
    }
}, {immediate: true})

watch(surveyLanguage, value => {
    // console.log('lang change detected', value);
    updateSurveyLanguage(value);
})

watch(vxLanguage, value => {
    if (survey.value == null || survey.value.languages == null) {
        return;
    }
    const searchResult = survey.value.languages.find(language => language.name == value.name);

    if (searchResult === undefined) {        
        if (survey.value.languages.length > 0) {
            surveyLanguage.value = survey.value.languages[0];
        }
    }
    else{
        surveyLanguage.value = searchResult;
    }
})
// watch(survey, function(newValue, oldValue){
//     console.log('Survey changed')
// },{deep: true})
</script>

<i18n>
    {
        "de": {
            "section": "Abschnitt",
            "back": "Zurück",
            "forth": "Weiter",
            "end": "Ende",
            "start": "Zum Anfang",
            "connection-lost": {
                "title": "Verbindung zum Server verloren",
                "message": "Wir versuchen gerade, die Verbindung wieder herzustellen..."
            }
        },
        "en": {
            "section": "section",
            "back": "Back",
            "forth": "Next",
            "end": "End",
            "start": "Intro",
            "connection-lost": {
                "title": "Connection lost",
                "message": "We are trying to reconnect you..."
            }
        }
    }
</i18n>

<style>
.survey-scope-tabs > .v-slide-group__prev, .survey-scope-tabs > .v-slide-group__next{
    min-width: 16px;
    width: auto;
    flex-basis: auto;
}

.survey-scope-tabs > .v-slide-group__prev{padding-right: 4px;}
.survey-scope-tabs > .v-slide-group__next{padding-left: 4px;}
</style>

<style scoped lang="scss">
@use '~/sass/guest' as styles;

.navigate-back{
    border-left-width: 8px;
}

@media screen and (min-width: 600px){
    .navigate-back{
        border-left-width: 4px;
    }

}
@media screen and (max-width: 600px){
    .scope-navigation-buttons{
        position: fixed;
        bottom: 0px;
        left: 0px;
        background-color: styles.$secondary;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 12px !important;
        padding-right: 12px !important;
        border-top: 4px solid styles.$primary;
        box-shadow: 0px -2px 4px -1px grey;
    }
    .scope-navigation-buttons > .v-btn{
        height: 36px;
    }
}

.survey-wrapper{
    max-width: 1200px;
    min-width: 0px;
}

@media screen and (min-width: 960px){
    .survey-wrapper{
        min-width: 600px;
    }
}

@media screen and (min-width: 1264px){
    .survey-wrapper{
        min-width: 800px;
    }
}
</style>