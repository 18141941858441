<template>
    <div class="d-flex flex-column gap-3 gap-sm-4 px-1 pb-1">    
        <v-card class="pa-4 border-l-3 border-l-sm-4 border-primary">
            <h2 class="text-h6 text-sm-h5 font-weight-regular">{{ name }}</h2>
            <p v-if="vxLanguage != null && vxLanguage.language_code == 'de'" class="text-body-2 text-md-body-1" style="white-space: pre-wrap;">{{ description }}</p>
        </v-card>    
        <survey-item 
            v-for="item, index in localItems"     
            :showErrorMessages="showErrorMessagesForItem(item)"
            :validated="!!item.validated"
            :item-id="item.id.toString()"
            v-model="item.answer.answer" 
            @update:model-value="handleItemInput(item)"
            :text="item.text_question" 
            :index="index+1"
            :scale="item.scale" 
            :type-id="item.type_id.toString()" 
            :required="item.required"
            :category="item.category"
            @lost:connection="handleLostConnection"
        >
        </survey-item>
    </div>
</template>

<script setup>
import { watch, ref, computed } from 'vue';
import surveyItem from './surveyItem.vue';
import { useStore } from 'vuex';

const props = defineProps({
    items: {
        type: Array,
        default: []
    },
    name: {
        type: String,
        default: ''
    },
    description:  {
        type: String,
        default: ''
    },
    completed: {
        type: Boolean,
        default: false
    },
    showErrorMessages: {
        type: Boolean,
        default: false
    }
})
// @TODO: Hier muss aufgeräumt werden ///MARK rEQUIRED NOT WWORKING

const emit = defineEmits(['update:completed', 'update:items', 'update:item', 'lost:connection']);

const store = useStore();

const vxLanguage = computed(()=>{return store.state.language});

const localItems = ref(props.items);
watch(localItems, function(newValue){
    emit('update:items', newValue)
})

// const localMarkRequiredItems = ref(props.markRequiredItems);

// const unansweredRequiredItems = computed(function(){
//     return localItems.value.filter(item => (!Boolean(item.answer.answer) || (Array.isArray(item.answer.answer) && item.answer.answer.length == 0)) && item.required);
// })

const showErrorMessagesForItem = function(item){
    return props.showErrorMessages && item.required;
}

const handleItemInput = function(item){
    emit('update:item', item);
}

const handleLostConnection = function(){
    console.log('con lost')
    emit('lost:connection');
}
// watch(unansweredRequiredItems, function(){
//     console.log('LOCAL ITEMS', localItems.value);
//     emit('update:completed', unansweredRequiredItems.value.length == 0);
// }, {immediate: true})

// watch(()=>props.markRequiredItems, function(newValue){
//     console.log('MARK rEQUIREd', localMarkRequiredItems.value)
//     localMarkRequiredItems.value = newValue;
// })

// watch(localMarkRequiredItems, function(){
//     console.log('MARK rEQUIREd', localMarkRequiredItems.value)
// }, {immediate: true})
</script>