<template>
    <v-card variant="elevated" color="white" :id="'survey-item-'+itemId">
        <div class="px-4 py-2 py-sm-4 py-md-6 border-l-3 border-l-sm-4 border-primary">
            <div class="survey-item-grid">
                    <div class="index align-self-center d-flex flex-wrap-nowrap">
                        <span class="text-primary text-h5">[</span>
                        <span class="font-weight-bold text-h6">{{ index }}</span>                
                        <span class="text-primary text-h5">]</span>
                    </div>
                    <div class="question" :class="{'mb-1': categoryExists, 'mb-md-0': categoryExists}">
                        <div v-if="categoryExists" class="d-inline border bg-primary pa-1 rounded-sm">{{ category }}</div>
                        {{ text+(required ? '*':'') }}
                    </div>        
                <div class="scale w-100 mb-1 mb-sm-0" :class="{'overflow-auto': typeId == 3 || typeId == 5}">
                    <v-text-field v-if="typeId == 1" 
                        :error-messages="requiredMessage" 
                        v-model="answer"
                        class="flex-grow-1" 
                        variant="outlined" 
                        density="compact" 
                        :hide-details="hideDetails" 
                        :label="t('placeholder')"
                    ></v-text-field>
                    <v-select v-else-if="typeId == 2" 
                        v-model="answer" 
                        :items="scale.scale_items" 
                        item-title="name"
                        item-value="id" 
                        :hide-details="hideDetails" 
                        density="compact" variant="outlined" 
                        :label="t('placeholder')" 
                        :error-messages="requiredMessage"
                    ></v-select>
                    <radio-scale v-else-if="typeId == 3 || typeId == 5" v-model="answer" 
                        :variant="typeId == 3 ? 'radio' : 'checkbox'"
                        :items="scale.scale_items" 
                        item-value="id" 
                        :multiple="typeId == 5" 
                        :error-messages="requiredMessage" 
                        :hide-details="hideDetails"                        
                    ></radio-scale>
                    <v-textarea v-else-if="typeId == 6" 
                        :label="t('placeholder')" 
                        v-model="answer" 
                        :hide-details="hideDetails" 
                        variant="outlined" 
                        density="compact" 
                        :error-messages="requiredMessage"
                    ></v-textarea>
                    <div class="d-flex flex-column" v-else-if="typeId == 4">                
                        <v-slider 
                            color="primary" 
                            v-model="answer" 
                            :min="scale.scale_items[0].name" 
                            :step="scale.scale_items[1].name" 
                            :max="scale.scale_items[2].name" 
                            :hide-details="hideDetails" 
                            :error-messages="requiredMessage"
                        ></v-slider>
                        <div>{{ t('answer') }}: {{ answer == null ? '/':answer }}</div>
                    </div>
                </div>
            </div>
        </div>
        <v-snackbar timeout="-1" v-model="errorSnackbar" location="bottom" position="fixed" content-class="mb-14 mb-sm-0">
            {{ t('error') }}
            <template v-slot:actions>
                <v-btn @click="errorSnackbar = false" icon="mdi-close"></v-btn>
            </template>
        </v-snackbar>
    </v-card>
</template>

<script setup>
import { ref, watch, computed, nextTick } from 'vue';
import { useI18n } from 'vue-i18n';
import axios from 'axios';
import radioScale from '../components/radioScale.vue';
const {t} = useI18n({useScope: 'local'});

const props = defineProps({
    index: {
        type: Number,
        default: ''
    },
    text: {
        type: String,
        default: ''
    },
    category: {
        type: String,
        default: ''
    },
    typeId: {
        type: String,
        default: ''
    },
    scale:{
        type: Object,
        default: null
    },
    required: {
        type: Boolean,
        default: false
    },
    itemId: {
        type: String,
        default: '-1'
    },
    modelValue: {
        default: ''
    },
    showErrorMessages: {
        type: Boolean,
        default: false
    },
    validated: {
        type: Boolean,
        default: false
    }
});

const emit = defineEmits(['update:modelValue', 'lost:connection']);

const errorSnackbar = ref(false);
var blockWatcher = false;
var inputTimeout = null;
var categoryExists = computed(()=>{
    return props.category != null && props.category != '';
})

const hideFromWatcher = function(callback = ()=>{}){
    blockWatcher = true;
    callback();
    nextTick(()=>blockWatcher = false);
}

const handleInput = function(){
    clearTimeout(inputTimeout);
    inputTimeout = setTimeout(()=>{        
        sendAnswerRequest()
        .then(response => {
            if(response.data.error.has){
                errorSnackbar.value = true;
                hideFromWatcher(()=>answer.value = originalAnswer.value)
            }        
            else{
                originalAnswer.value = answer.value;
            }
        })
        .catch(error => {   
            if(error.code === "ERR_NETWORK"){
                emit('lost:connection');
                return;
            }
            errorSnackbar.value = true;         
            hideFromWatcher(()=>answer.value = originalAnswer.value)
        })
    }, 800);
}

// @TODO Handle Request error
const sendAnswerRequest = async function(){
    var data = {
        participant_answer: answer.value,
        item_id: props.itemId
    }
              
    const response = axios.post('/upsert/participant/answer', data);
    return response;
}

const getCurrentAnswer = function(){
    if(props.modelValue == null){
        return '';
    }
    if (props.typeId == 2) {
        return props.scale.scale_items.filter(item => item.id == props.modelValue);
    }
    return props.modelValue;
}

const originalAnswer = ref(getCurrentAnswer());
const answer = ref(getCurrentAnswer());

const requiredMessage = computed(function(){
    return (props.showErrorMessages && !props.validated) ? t('required') : '';
});
const label = computed(function(){
    return t('placeholder');
})
const hideDetails = computed(function(){
    return !(props.showErrorMessages && !props.validated);
})

watch(answer, function(newValue, oldValue){    
    emit('update:modelValue', answer.value);
    if (blockWatcher) return;
    handleInput(newValue, oldValue);
},{deep: true})

</script>

<style>
    .index{grid-area: index}
    .question{grid-area: question;}

    .scale{grid-area: scale; justify-self: end;}

    .survey-item-grid{
        display: grid;
        column-gap: 8px;
        align-items: center;
        grid-template-columns: 36px 12fr 13fr;
        grid-template-areas:
         "index question scale"
    }

    @media screen and (max-width: 960px) {
        .scale{grid-area: scale; justify-self: start;}
        .survey-item-grid{
            gap: 4px;
            grid-template-rows: auto auto;
            grid-template-areas:
            "index question question"
            ". scale scale"
        }
    }

    @media screen and (max-width: 600px) {
        .survey-item-grid{
            grid-template-rows: auto auto auto;
            row-gap: 8px;
            column-gap: 0px;
            grid-template-areas:
            "index index index"
            "question question question"
            "scale scale scale"
        }
    }

    /* @media screen and (max-width: 600px) {
        .survey-item-grid{
            grid-template-rows: auto auto;
            grid-template-areas:
            "index question question"
            "scale scale scale"
        }
    } */

</style>

<i18n>
    {
        "de": {
            "placeholder": "Antwort",
            "required": "Pflichtfrage",
            "answer": "Antwort",
            "error": "Es ist ein unerwarteter Fehler aufgetreten"
        },
        "en": {
            "placeholder": "Answer",
            "required": "required",
            "answer": "Answer",
            "error": "An unexpected error occured"
        }
    }
</i18n>